export const symbolData = {
  "AUD": "$",
  "BDT": "৳",
  "GBP": "£",
  "CAD": "C$",
  "EUR": "€",
  "FJD": "FJ$",
  "HKD": "HK$",
  "INR": "₹",
  "KES": "KSh",
  "MYR": "RM",
  "MVR": ".ރ",
  "MUR": "Rs",
  "NGN": "₦",
  "NAD": "N$",
  "NPR": "रू",
  "NZD": "$",
  "OMR": "ر.ع",
  "PKR": "Rs",
  "PHP": "₱",
  "QR": "ر.ق",
  "SAR": "SR",
  "SGD": "$",
  "ZAR": "R",
  "LKR": "Rs",
  "SEK": "kr",
  "CHF": "Fr.",
  "THB": "฿",
  "AED": "د.إ",
  "USD": "$",
  "JPY": "¥",
  "ZMK": "ZK"
}
