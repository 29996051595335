import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@/lib/utils";

export const ordersApiSlice = createApi({
  baseQuery: axiosBaseQuery,
  reducerPath: "api3",
  tagTypes: ["Orders", "OrderDetails", "General"],
  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: () => "/api/rest/v1/orders/",
      keepUnusedDataFor: 60 * 10,
      providesTags: ['Orders'],
      serializeQueryArgs: () => 'AllOrders',
    }),
    getOrdersDetails: builder.query({
      query: (token) => `/api/rest/v1/orders/${token}/`,
      keepUnusedDataFor: 60,
      providesTags: (result, error, token) => {
        if (!error) return [{ type: "OrderDetails", token }]
        return []
      },
      serializeQueryArgs: (token) => token,
    }),
    invalidateCache: builder.mutation({
      query: () => null,
      invalidatesTags: () => ["Orders"]
    }),
  }),
});

export const {
  useGetAllOrdersQuery,
  useGetOrdersDetailsQuery,
} = ordersApiSlice;
