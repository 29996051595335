import {
  setUserInfo,
  setExpertInfo,
  setUtmInfo,
  setHeaderInfo,
} from "@/store/general/generalSlice";
import { getUtmQuery, getExpertInfo } from "./utils";


export const handleUserData = (response, dispatch) => {
  dispatch(setUserInfo(response));
  const data = JSON.stringify(response);
  localStorage.setItem("user_info", data);
  if (response.expert) {
    const expert_info = JSON.stringify(response.expert);
    localStorage.setItem("expert_info", expert_info);
  }
};

export const getInitialData = async (dispatch) => {
  const utmQuery = getUtmQuery();
  if (utmQuery && utmQuery.length) {
    dispatch(setUtmInfo(utmQuery));
  }
  let expertFound = false;
  const user_obj = localStorage.getItem("user_info");
  if (user_obj) {
    const user_info = JSON.parse(user_obj);
    dispatch(setUserInfo(user_info));
    if (user_info.expert) {
      expertFound = true;
      dispatch(setExpertInfo(user_info.expert));
    }
  }
  if (!expertFound) getExpertInfo(dispatch);
};

export const handleLogout = async (dispatch) => {
  dispatch(setUserInfo(null));
  dispatch(setHeaderInfo({
    cartCount: 0,
    wishlistCount: 0,
  }));
  localStorage.removeItem("user_info");
};


export const getUserDetails = (user) => {
  return {
    'name':  user ? ( user.name ? user.name : user.full_name  ) : "",
    'email': ( user && user.email ) ? user.email : "", 
    'phone': ( user && user.phone ) ? user.phone : "",
    "hash": "hash-value",
  };
}