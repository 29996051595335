const no_urls = ["/account", "/orders", "/sign-in", "/sign-up", "/profile", '/support', '/search', "/cart", "/wishlist", "/checkout"]

const cat_combinations = [
  'gender=men',
  'gender=women',
  'gender=women&style=eternity',
  'metal_color=white-gold',
  'metal_color=yellow-gold',
  'metal_color=rose-gold',
  'shape=round&gender=women',
  'shape=princess&gender=women',
  'shape=cushion&gender=women',
  'shape=oval&gender=women',
  'shape=heart&gender=women',
  'shape=pear&gender=women',
  'shape=emerald&gender=women',
  'style=plain-solitaire',
  'style=vintage&gender=women',
  'style=halo&gender=women',
  'style=side-stone&gender=women',
  'style=three-stone&gender=women',
  'metal_color=white-gold&gender=women',
  'metal_color=rose-gold&gender=women',
  'style=eternity'
]

const cat_list = ['earrings', 'pendants', 'bangles', 'cufflinks', 'nosepins', 'mangalsutras', 'necklaces', 'bracelets', 'rings']
const cat_url_list = cat_list.map(x => `/${x}/`);

export const GLOBAL_COMPANY_CONFIG = {
  'company_name': 'ORNAZ',
  "legal_name": "ORNAZ Jewellery Private Limited",
  "GSTIN": "06AAGCB7359E1ZN",
  "default_og_image_url": "https://d3rodw1h7g0i9b.cloudfront.net/favicons/mstile-1200x630.png",
  "domain": "ornaz.com",
  "seo": {
    "title": "ORNAZ - Engagement Rings and Fine Jewellery",
    "description": "India’s leading online engagement rings brand helping you celebrate love. View our unique collection of Engagement Rings, Diamond Rings and Wedding Rings.",
    "seo_keywords": "Gold, gold rings, diamond jewellery, best designs, men's band, rings for men, buy, online, latest designs, platinum, couple rings, gold rate",
  }
}

const attribute_choice_value_map = [
  { 'attribute__slug': 'metal_color', 'slug': 'white-gold', 'name': 'White Gold' },
  { 'attribute__slug': 'metal_color', 'slug': 'yellow-gold', 'name': 'Yellow Gold' },
  { 'attribute__slug': 'metal_color', 'slug': 'rose-gold', 'name': 'Rose Gold' },
  { 'attribute__slug': 'gender', 'slug': 'men', 'name': 'Men' },
  { 'attribute__slug': 'gender', 'slug': 'women', 'name': 'Women' },
  { 'attribute__slug': 'occasion', 'slug': 'casual', 'name': 'Casual' },
  { 'attribute__slug': 'occasion', 'slug': 'fashion', 'name': 'Fashion' },
  { 'attribute__slug': 'style', 'slug': 'studs', 'name': 'Studs' },
  { 'attribute__slug': 'style', 'slug': 'drops', 'name': 'Drops' },
  { 'attribute__slug': 'style', 'slug': 'hoops-and-huggies', 'name': 'Hoops and Huggies' },
  { 'attribute__slug': 'style', 'slug': 'cocktail', 'name': 'Cocktail' },
  { 'attribute__slug': 'stone_type', 'slug': 'solitare', 'name': 'Solitare' },
  { 'attribute__slug': 'stone_type', 'slug': 'diamond', 'name': 'Diamond' },
  { 'attribute__slug': 'occasion', 'slug': 'engagement', 'name': 'Engagement' },
  { 'attribute__slug': 'occasion', 'slug': 'everyday', 'name': 'Everyday' },
  { 'attribute__slug': 'style', 'slug': 'bands', 'name': 'Bands' },
  { 'attribute__slug': 'shape', 'slug': 'round', 'name': 'Round' },
  { 'attribute__slug': 'shape', 'slug': 'princess', 'name': 'Princess' },
  { 'attribute__slug': 'shape', 'slug': 'cushion', 'name': 'Cushion' },
  { 'attribute__slug': 'shape', 'slug': 'oval', 'name': 'Oval' },
  { 'attribute__slug': 'shape', 'slug': 'heart', 'name': 'Heart' },
  { 'attribute__slug': 'shape', 'slug': 'pear', 'name': 'Pear' },
  { 'attribute__slug': 'style', 'slug': 'three-stone', 'name': 'Three Stone' },
  { 'attribute__slug': 'style', 'slug': 'vintage', 'name': 'Vintage' },
  { 'attribute__slug': 'style', 'slug': 'plain-solitaire', 'name': 'Plain Solitaire' },
  { 'attribute__slug': 'topengagement', 'slug': 'yes', 'name': 'YES' },
  { 'attribute__slug': 'stone_type', 'slug': 'gemstone', 'name': 'Gemstone' },
  { 'attribute__slug': 'shape', 'slug': 'emerald', 'name': 'Emerald' },
  { 'attribute__slug': 'style', 'slug': 'pave', 'name': 'Pave' },
  { 'attribute__slug': 'style', 'slug': 'cluster', 'name': 'Cluster' },
  { 'attribute__slug': 'style', 'slug': 'eternity', 'name': 'Eternity' },
  { 'attribute__slug': 'style', 'slug': 'mangalsutra', 'name': 'Mangalsutra' },
  { 'attribute__slug': 'style', 'slug': 'solitaire-with-diamond-band', 'name': 'Solitaire With Diamond Band' },
  { 'attribute__slug': 'style', 'slug': 'side-diamond', 'name': 'Side Diamond' },
  { 'attribute__slug': 'topengagement', 'slug': 'no', 'name': 'NO' },
  { 'attribute__slug': 'style', 'slug': 'halo', 'name': 'Halo' },
  { 'attribute__slug': 'shape', 'slug': 'radiant', 'name': 'Radiant' },
  { 'attribute__slug': 'shape', 'slug': 'asscher', 'name': 'Asscher' },
  { 'attribute__slug': 'offers', 'slug': 'big-day-sale-offer', 'name': 'Big Day Sale Offer' },
  { 'attribute__slug': 'collections', 'slug': 'mystic', 'name': 'Mystic' },
  { 'attribute__slug': 'shape', 'slug': 'marquise', 'name': 'Marquise' },
  { 'attribute__slug': 'style', 'slug': 'single-halo', 'name': 'Single Halo' },
  { 'attribute__slug': 'style', 'slug': 'double-halo', 'name': 'Double Halo' },
  { 'attribute__slug': 'shape', 'slug': 'trillion', 'name': 'Trillion' },
  { 'attribute__slug': 'collections', 'slug': 'gift', 'name': 'Gift' },
  { 'attribute__slug': 'style', 'slug': 'earrings', 'name': 'Earrings' },
  { 'attribute__slug': 'style', 'slug': 'necklace', 'name': 'Necklace' },
  { 'attribute__slug': 'style', 'slug': 'stackable-bands', 'name': 'Stackable Bands' }
];


export const createSearchParamsString = (searchParams) => {
  let search = ""
  for (const key in searchParams) {
    const value = searchParams[key]
    if (!Array.isArray(value)) {
      search += `&${key}=${value}`
    } else {
      let temp = ""
      for (const val of value) {
        temp += `&${key}=${val}`
      }
      search += temp;
    }
  }
  return search.slice(1,);
}

export const isNumeric = (str) => {
  if (typeof str != "string") return false
  return !isNaN(str) && !isNaN(parseFloat(str))
}

export const _hashUrl = (url) => {
  url = url.toLowerCase();

  // step 1: check no track urls
  for (const no_url of no_urls) {
    if (url.startsWith(no_url)) {
      return null
    }
  }

  // step 2: For catalog pages
  if (url.startsWith("/jewellery")) {
    const [requestUrl, query] = url.includes("?") ? url.split("?") : [url, ""];
    const filters = [];

    for (const qParam of query.split("&")) {
      const [key, val] = qParam.split("=");
      const qsMap = attribute_choice_value_map;
      // You need to implement the getAttribChoiceValueMap function.
      for (const item of qsMap) {
        if (item['attributeSlug'] === key && item.slug.toLowerCase() === val.toLowerCase()) {
          filters.push(`attributes_${item['attributeSlug']}=${item['slug']}`);
          break;
        }
      }
    }

    filters.sort();
    const queryHash = filters.join("&");

    if (queryHash) {
      return `/${requestUrl}?${queryHash}`;
    }

    return requestUrl;
  }

  // step 3: for pdp pages.
  for (const item of cat_url_list) {
    if (url.startsWith(item)) {
      url = url.replace(item, "")
      return url.split("-")[0]
    }
  }

  // step 4: other urls 
  const [requestUrl] = url.split("?");
  return requestUrl;
}

export function generate_metadata({ url, title, description, seo_keywords, canonical }, og_images) {
  return {
    "url": url,
    "title": title,
    "description": description,
    "keywords": seo_keywords,
    "alternates": {
      "canonical": canonical ? canonical : url,
    },
    "openGraph": {
      "type": "website",
      "locale": "en_IN",
      "site_name": GLOBAL_COMPANY_CONFIG["company_name"],
      "title": title,
      "description": description,
      "images": og_images,
    },
    "twitter": {
      "card": "Website",
      "creator": GLOBAL_COMPANY_CONFIG["legal_name"],
      "site": GLOBAL_COMPANY_CONFIG["company_name"],
      "domain": GLOBAL_COMPANY_CONFIG["domain"],
      "title": title,
      "description": description,
    }
  }
}

export const og_image_data = (category_name, params = {}) => {
  // """ default seo data for category corresponding to params."""
  if (!category_name) return GLOBAL_COMPANY_CONFIG["default_og_image_url"];
  const gender = params['gender'];
  const is_gift = params['collections'] == 'gift';

  let og_image_url = GLOBAL_COMPANY_CONFIG["default_og_image_url"]
  // step 1: for gifts
  if (is_gift) {
    og_image_url = "https://d3d5st4bexye3p.cloudfront.net/og_images/instance/7757ee6e.jpg"
    // step 2: for rings
  } else if (["ring", "rings"].includes(category_name.toLowerCase())) {
    if (gender == "women") {
      og_image_url = "https://d3d5st4bexye3p.cloudfront.net/og_images/instance/72d6a7c6.jpg"
    } else if (gender == "men") {
      og_image_url = "https://d3d5st4bexye3p.cloudfront.net/og_images/instance/7757ee6e.jpg"
    } else {
      og_image_url = "https://d3d5st4bexye3p.cloudfront.net/og_images/instance/72d6a7c6.jpg"
    }
    // step3: for other categories.
  } else {
    og_image_url = GLOBAL_COMPANY_CONFIG["default_og_image_url"]
  }
  return og_image_url
}


export const home_page_seo_data = () => {
  const generalSeo = {
    "url": process.env.NEXT_PUBLIC_API_URL + "/",
    ...GLOBAL_COMPANY_CONFIG['seo']
  }
  const ogImage = [{
    url: GLOBAL_COMPANY_CONFIG['default_og_image_url'],
    width: 1200,
    height: 630,
    alt: "ORNAZ"
  }]
  return generate_metadata(generalSeo, ogImage);
}

export const category_seo_data = (category_name, params={}) => {
  // """ default seo data for category corresponding to params."""
  const gender = params['gender'];
  const is_gift = params['collections'] == 'gift';
  let title = "";
  let description = "";
  let seo_keywords = "";
  const searchParams = "?" + createSearchParamsString(params);
  const baseUrl = process.env.NEXT_PUBLIC_API_URL + "/jewellery";
  const canonical = baseUrl + `?page=${params["page"] || 1}`;

  // step 1: for gifts
  if (is_gift) {
    title = "Buy Fine Jewellery Gifts | ORNAZ";
    description = "Discover our brand new fine jewellery collection for your relationship after you say I DO.";
    seo_keywords = "diamond rings, diamond jewellery, couple gifts, anniversary gifts, fine jewellery, birthday gifts, gold jewellery, gold rings";
    // step 2: for rings
  } else if (["ring", "rings"].includes(category_name.toLowerCase())) {
    if (gender == "women") {
      title = "Buy Diamond Engagement Rings for Women Online | ORNAZ";
      description = "Buy Diamond Engagement Rings for men and women at Best Prices. Explore the latest Platinum, Gold & Diamond Engagement Rings. Buy GIA certified engagement Rings now at ORNAZ.";
      seo_keywords = "Gold, Gold Rings, Diamond, Diamond ring, Rings, Engagement Rings, Jewellery, Gold jewellery, diamond jewellery, Buy, Online";
    } else if (gender == "men") {
      title = "Buy Men's Engagement Rings | ORNAZ";
      description = "Choose from the latest collection of Gold, Platinum and Diamond Engagement Rings for Men. Shop Engagement Rings for Men at Best Price. Buy Men's Rings designs online at ORNAZ";
      seo_keywords = "Gold men's Rings, men's band, buy Rings online, gold Rings, Rings for man, engagement ring, couple Rings, Rings for couples";
    } else {
      title = "Buy Engagement Rings For Couples Online | ORNAZ";
      description = "Shop from the latest Rings design collection - Find the best Rings for couples online. Choose from the latest collection of Diamonds rings, engagement rings, solitaire Rings, platinum Rings, gold Rings at best price.";
      seo_keywords = "Couple Rings, couples, gifts, gifts for women, gift for men, customise, rings, gold, gold Rings, platinum, best designs";
    }
    // step3: for other categories.
  } else {
    title = `Buy ${category_name} Online | ORNAZ`;
    description = `Find the best gift for your partner at ORNAZ. Shop from the latest collection of 1000+ designs of ${category_name.toLowerCase()} at best price.`;
    seo_keywords = "Gold rings, Gold rate, platinum jewellery, eternity bands, men's ring, women rings, earring, pendants, gold jewellery, engagement rings, rings for men, designs, trending designs, diamond studs";
  }
  const generalSeo = {
    "url": baseUrl + (category_name !== "All" ? ("/" + category_name) : "") + searchParams,
    title,
    description,
    seo_keywords,
    canonical
  }
  const ogImage = [{
    url: og_image_data(category_name.toLowerCase(), params),
    width: 1200,
    height: 630,
    alt: title
  }]

  return generate_metadata(generalSeo, ogImage)
}

export function default_seo_data(url) {
  // step 1: deault values.
  let company_name = GLOBAL_COMPANY_CONFIG["legal_name"];
  let title = GLOBAL_COMPANY_CONFIG["seo"]["title"];
  let seo_keywords = GLOBAL_COMPANY_CONFIG["seo"]["keywords"];
  let description = GLOBAL_COMPANY_CONFIG["seo"]["description"];

  let suffix = url.split("?")[0].split("/").slice(-2)[0];

  let page_name;

  // step 2: modify 
  if (suffix && suffix === "search") {
    description = "Say 'I Do' with ORNAZ - Shop Our Collection of Engagement Rings";
  } else if (suffix || (url.indexOf("support") === -1 || url.indexOf("order") !== -1)) {
    page_name = suffix
      .split("-")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");

    if (page_name.toLowerCase() === "faq") {
      page_name = "FAQ";
    }

    title = page_name + "-" + company_name;
    description = "Browse through ORNAZ " + page_name + " page for more details";
  } else {
    page_name = company_name;
    title = site_settings ? site_settings.title : "";
    description = site_settings ? site_settings.description : "";
  }
  const ogImage = [{
    url: og_image_data(),
    width: 1200,
    height: 630,
    alt: title
  }]

  return generate_metadata({
    "url": url, "title": title, "description": description, "seo_keywords": seo_keywords,
  }, ogImage)
}

export const validCategories = cat_list;

export const getInitialCategoryData = ({basic_details, metal_color}) => {
  if(!basic_details) return {}
  return {
    diamond_color:
      basic_details.color_list && basic_details.color_list.length > 0
        ? basic_details.color_list[0]
        : null,

    diamond_clarity:
      basic_details.clarity_list && basic_details.clarity_list.length > 0
        ? basic_details.clarity_list[0]
        : null,

    mount_size:
      basic_details.mount_size_list && basic_details.mount_size_list.length > 0
        ? basic_details.mount_size_list[0]
        : null,

    metal_carat:
      basic_details.metal_carat_list &&
      basic_details.metal_carat_list.length > 0
        ? basic_details.metal_carat_list[0]
        : null,

    diamond_type:
      basic_details.diamond_type_list &&
      basic_details.diamond_type_list.length > 0
        ? basic_details.diamond_type_list[0]
        : null,

    size: null,
    metal_color
  };
};

export const ORDER_LOADERS_INFO = {
  loaderID: "ordersLoader",
  subTitleId1: "fetchingOrders",
  subTitleId2: "noItemsOrders"
}