import { instance as apiService } from ".";
import { handleErrors } from "./utils";

export const oneTapAPI = async (data) => {
  try {
    const res = await apiService.post(
      "/api/rest/v1/account/oauth-google/one-tap/",
      data
    );
    return res.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const logoutAPI = async () => {
  try {
    await apiService.post("/api/rest/v1/account/logout/");
    return true;
  } catch (error) {
    handleErrors(error);
  }
};


export const loginAPI = async (data) => {
  try {
    const res = await apiService.post("/api/rest/v1/account/login/", data);
    return res.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const registerAPI = async (data) => {
  try {
    const res = await apiService.post("/api/rest/v1/account/register/", data);
    return res.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const oauthGoogleAPI = async (data) => {
  try {
    const res = await apiService.post("/api/rest/v1/account/oauth-google/", data);
    return res.data;
  } catch (error) {
    handleErrors(error);
  }
};

export const resetPasswordAPI = async (data) => {
  try {
    await apiService.post("/api/rest/v1/account/reset-password/", data);
    return true;
  } catch (error) {
    handleErrors(error);
  }
};

